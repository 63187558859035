


































































import { computed, defineComponent, onBeforeUnmount, onMounted, provide, ref, Ref } from '@vue/composition-api'
import store from '@/store/store'
import addPartsTemplate from '../../components/templates/addPartsTemplate.vue'
import {
  ApolloClients,
  useMutation,
  UseMutationReturn,
  useQuery,
  UseQueryReturn,
  useResult,
  UseResultReturn
} from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo'
import { GET_SYSTEM, SET_SYSTEM_ADD_ON_ITEMS } from '@/api/graphql/Constants/Inventory'
import {
  Create__Purchases_PurchasedItems__Input,
  Inventory_System,
  Mutation,
  Purchases_Purchase,
  Purchases_PurchasedItems,
  Update__Purchases_PurchasedItems__Input
} from '@/models/generated/graphql/ErpBackend'
import { GetPtOrStId } from '@/lib/gqlDataGetters'
import { FormatMoney } from '@/lib/agGridColumnConfiguration'
import { IGuiPurchasesItem } from '@/models/vuexStateModels'
import { CREATE_PURCHASED_ITEMS, GET_PURCHASE, UPDATE_PURCHASED_ITEMS } from '@/api/graphql/Constants/Orders'
import { ErrorCachingObject } from '@/models/errorAndValidationModels'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'
import { c } from '@/lib/Currency'

export default defineComponent({
  name: 'PreBreakdownAudit',
  components: {
    'add-part': addPartsTemplate
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup (props, { root: { $router } }) {
    provide(ApolloClients, {
      default: apolloClient
    })
    // manually keeping track of this loading variable because this process contains many steps

    const isCompletingAudit: Ref<boolean> = ref(false)
    const isAuditComplete: Ref<boolean> = ref(false)

    // SYSTEM INFO QUERY
    const { result, loading, onError, onResult }: UseQueryReturn<Inventory_System, { id: string }> = useQuery(
      GET_SYSTEM,
      () => ({
        id: props.id
      }),
      () => ({
        enabled: Number(props.id) > 0
      })
    )
    onResult(result => {
      // @ts-ignore
      cacheName.value += result?.data?.inventory_system.id
      CheckCacheForData()
      purchaseQueryIsEnabled.value = true
    })
    onError(() => {
      const isOnline = navigator.onLine
      if (!isOnline) {
        store.dispatch('notifications/createSnackbar', {
          message: 'We could not retrieve details from this system. It looks like you are offline.',
          color: 'error'
        })
      } else {
        store.dispatch('notifications/createSnackbar', {
          message: 'We could not retrieve details from this system. Try again or contact support.',
          color: 'error'
        })
      }
    })

    // system used throughout the process
    const system: UseResultReturn<Inventory_System | null> = useResult(result, null)
    // amount to change system purchase value to
    const valueToUpdateSystemWith: Ref<number> = computed(() => {
      let total = 0
      parts.value.forEach(p => {
        total = c(total).add(p.cost).value
      })
      return c(system.value?.purchases_items_details?.current_cost?.amount ?? 0).subtract(c(total)).value
    })
    // name of the cache string to use when caching when errors happen
    const cacheName: Ref<string> = ref('breakdown-audit')
    const hasValidMoney: Ref<boolean> = computed(() => partsTotal.value <= system?.value?.purchases_items_details?.current_cost?.amount! ?? 0)
    // helper to set snack bar message for updating the user
    function SetInfoSnackBar (message: string) {
      store.dispatch('notifications/createSnackbar', {
        message: message,
        color: 'info',
        timeout: 5000
      })
    }

    const purchaseQueryIsEnabled: Ref<boolean> = ref(false)
    // SYSTEM PURCHASE QUERY --> @returns the purchase information that the system is apart of
    const { result: purchaseResult, onError: onGetPurchaseError }: UseQueryReturn<Purchases_Purchase, { id: string }> = useQuery(
      GET_PURCHASE,
      () => ({
        id: system.value?.purchases_items_details?.transaction.id ?? '0'
      }),
      () => ({
        enabled: purchaseQueryIsEnabled.value
      })
    )
    onGetPurchaseError(() => {
    })
    // SYSTEM PURCHASE RESULT --> this is how we actually store the system information
    // useResult does a lot of heavy lifting for us for null checking, undefined checking, etc
    const purchase: UseResultReturn<Purchases_Purchase | null> = useResult(purchaseResult, null)

    // we will store the IDs of the newly added parts here specifically in case we need to cache them in case of errors
    // but we will need them in some for to update the system anyways
    const newlyCreatedParts : Ref<string[]> = ref([])

    // method to kick off the completion
    function CompleteAudit () {
      SetInfoSnackBar(`Adding the parts to the Purchase Order: ${system.value!.purchases_items_details!.transaction.id}`)
      isCompletingAudit.value = true
      let lineNumber: number = Math.max(...purchase.value!.pt_items!.map(p => p.line_number)) + 1
      const preparationParts: Create__Purchases_PurchasedItems__Input[] = []
      for (const part of parts.value) {
        preparationParts.push({
          transaction_id: system.value!.purchases_items_details!.transaction.id,
          current_cost: part.cost,
          original_cost: part.cost,
          part_id: `${part.id}`,
          line_number: lineNumber
        })
        lineNumber++
      }
      CompleteAuditMutation({ items: preparationParts })
    }

    // COMPLETE AUDIT MUTATION --> this creates the purchased items (and inventory items) to later add to the system
    /* The first mutation */
    const {
      mutate: CompleteAuditMutation,
      onError: onCompletionFailure,
      onDone: onCompletionSuccess
    }: UseMutationReturn<Mutation, { items: Create__Purchases_PurchasedItems__Input[] }> = useMutation(
      CREATE_PURCHASED_ITEMS
    )
    onCompletionFailure(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'We could not complete this audit. Please try again or contact support. We have cached this data so you do not lose it!',
        color: 'error',
        timeout: 10000
      })
      PrepareDataForCaching()
      isCompletingAudit.value = false
    })
    onCompletionSuccess(result => {
      SetInfoSnackBar('Added items to purchase order, now adding items to the parent system.')
      // @ts-ignore
      newlyCreatedParts.value = result?.data.items.map((i: Purchases_PurchasedItems) => i.item!.id)
      const input: { system: string, items: string[] } =
        { system: system.value!.id, items: newlyCreatedParts.value }
      AddItemsToSystemMutation(input)
    })

    /* The second mutation */
    // ADD ITEMS TO THE SYSTEM MUTATION (ADD ON ITEMS)
    const {
      mutate: AddItemsToSystemMutation,
      onError: onAddingFailure,
      onDone: onAddingSuccess
    }: UseMutationReturn<Mutation, { system: string, items: string[] }> = useMutation(
      SET_SYSTEM_ADD_ON_ITEMS
    )
    onAddingFailure(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'Created items and added them to purchase but could not add them "under" the system. ' +
          'We have cached this data so you do not lose it!',
        color: 'error',
        timeout: 10000
      })
      const dataToCache: ErrorCachingObject = { name: 'audit', data: { id: system.value!.id, data: newlyCreatedParts } }
      locStore.set(LOCAL_STORAGE_KEYS.errorCache, JSON.stringify(dataToCache))
      isCompletingAudit.value = false
    })
    onAddingSuccess(() => {
      UpdateSystemValueMutation({ input: [{ id: system.value!.purchases_items_details!.id, current_cost: valueToUpdateSystemWith.value }] })
    })

    /* The final mutation */
    // Update System value mutation
    const {
      mutate: UpdateSystemValueMutation,
      onError: onUpdateSystemValueFailure,
      onDone: onUpdateSystemValueSuccess
    }: UseMutationReturn<Mutation, { input: Update__Purchases_PurchasedItems__Input[] }> = useMutation(
      UPDATE_PURCHASED_ITEMS
    )
    onUpdateSystemValueFailure(() => {
      store.dispatch('notifications/createSnackbar', {
        message: 'All but changing the value of the system succeeded. Please contact support.',
        color: 'warning'
      })
      isCompletingAudit.value = false
    })
    onUpdateSystemValueSuccess(() => {
      isCompletingAudit.value = false
      store.dispatch('notifications/createSnackbar', {
        message: 'Successfully completed the audit. This system can now be broken down into the added parts.',
        color: 'success'
      })
      $router.go(-1)
    })

    const parts: Ref<IGuiPurchasesItem[]> = ref([])
    const partsTotal: Ref<number> = computed(() => {
      let total: number = 0
      for (const part of parts.value) total += part.cost
      return total
    })

    // we want to make sure the user actually wants to go back here because there could potentially be a lot of
    // data they have entered that could get lost
    function GoBack () {
      if (parts.value.length > 0) {
        const confirm = window.confirm('Are you sure you want to go back? You have unsaved changes')
        if (confirm) $router.go(-1)
      } else $router.go(-1)
    }

    // caching methods
    function PrepareDataForCaching () {
      if (system.value) {
        const stringyData = JSON.stringify(parts.value)
        CacheData(stringyData)
      }
    }
    function CacheData (data: string) {
      // @ts-ignore
      locStore.set(cacheName.value, data)
    }
    function CheckCacheForData () {
      // @ts-ignore
      const cache = locStore.get(cacheName.value)
      if (cache) {
        const confirm = window.confirm('We have found some previously cached data for an audit of this system,' +
          'would you like to load it? Clicking cancel will remove this cached data.')
        if (confirm) {
          PullDataFromCache()
        } else {
          RemoveCache()
        }
      }
    }
    function PullDataFromCache () {
      // @ts-ignore
      const parsed = locStore.get(cacheName.value)
      if (parsed) {
        try {
          if (system.value && parts.value.length !== parsed.length) {
            parts.value = parsed
            RemoveCache()
          }
        } catch {
          store.dispatch('notifications/createSnackbar', {
            message: 'Unfortunately it looks like the cache did not make it through the parsing process. :(',
            color: 'error'
          })
        }
      }
    }
    function RemoveCache () {
      locStore.remove(cacheName.value)
    }

    // watch for online status to change and update accordingly
    function UpdateOnlineStatus () {
      const isOnline = navigator.onLine

      if (isOnline) {
        store.dispatch('notifications/createSnackbar', {
          message: 'Looks like you have come back online! Since you did not leave your data is still here!',
          color: 'success',
          timeout: 10000
        })
      } else {
        if (parts.value.length > 0) {
          store.dispatch('notifications/createSnackbar', {
            message: 'Oh no! You have gone offline, we have cached your work for when you come back.',
            color: 'error',
            timeout: 10000
          })
          PrepareDataForCaching()
        }
      }
    }
    onMounted(() => {
      window.addEventListener('offline', UpdateOnlineStatus)
      window.addEventListener('online', UpdateOnlineStatus)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('offline', UpdateOnlineStatus)
      window.removeEventListener('online', UpdateOnlineStatus)
    })

    return {
      parts,
      system,
      valueToUpdateSystemWith,
      loading,
      GetPtOrStId,
      FormatMoney,
      hasValidMoney,
      GoBack,
      CompleteAudit,
      isCompletingAudit,
      isAuditComplete
    }
  }
})

